module.exports = {
    "compound": "https://app.compound.finance/#",
    "aave": "https://aave.com/",
    "uniswap": "https://uniswap.org/",
    "nest": "https://nestdapp.io/",
    "cofi": "https://cofix.io/",
    "chainlink": "https://chain.link/",
    "usd coin": "https://www.centre.io/",
    "tether usd": "https://tether.to/en/",
    "true usd": "https://www.trueusd.com/",
    "cryptokitties": "https://www.cryptokitties.co/",
    "kyber network crystal": "https://kyber.network/",
    "wrapped ether": "https://james-sangalli.github.io/weth-made-easy/",
    "basic attention token": "https://basicattentiontoken.org/",
    "dai stablecoin": "https://makerdao.com/en/",
    "matic": "https://polygon.technology/",
    "looksrare": "https://looksrare.org/",
    "fantom token": "https://fantom.foundation/",
    "kyber": "https://kyber.network/",
    "trueusd": "https://trueusd.com/",
    "decentraland mana": "https://decentraland.org/",
    "sushitoken": "https://www.sushi.com/",
    "mask": "https://mask.io/",
    "ethereum name service": "https://ens.domains/",
    "ape": "https://apecoin.com/",
    "olympus": "https://www.olympusdao.finance/",
    "shiba inu": "https://www.shibatoken.com/",
    "zora": "https://zora.co/",
    "0x protocol token": "https://www.0x.org/",
    "shiba swap": "https://shibaswap.com/#/",
    "lido": "https://lido.fi/",
    "opensea": "https://opensea.io/"
}
